import { useDebounceCallback } from "@react-hook/debounce";

import React, { useCallback, useState } from "react";
import Autosuggest, { InputProps } from "react-autosuggest";

import { OtherIngredient } from "../../interfaces";

interface SingleProps {
  isMulti: false | undefined;
  onSelected: (oi: OtherIngredient) => void;
}

interface MultiProps {
  isMulti: true;
  onSelected: (oi: OtherIngredient) => void;
  onRemoveSelected: (oi: OtherIngredient) => void;
  selected: OtherIngredient[];
}

type Props = SingleProps | MultiProps;

const getSuggestionValue = (suggestion: OtherIngredient) => suggestion;

const renderSuggestion = (suggestion: OtherIngredient) => <span>{suggestion.name.toLowerCase()}</span>;

const renderSuggestionsContainer = ({ containerProps, children }) => {
  return (
    <div {...containerProps} className={`${containerProps.className} has-width-full is-absolute is-z-top`}>
      {children}
    </div>
  );
};

const renderMultiInputComponent = (values: OtherIngredient[], onRemoveSelected: (oi: OtherIngredient) => void) => (
  inputProps: any
) => (
  <div className="autosuggest">
    <div className="tags">
      {values.map((oi) => (
        <div className="tags has-addons" key={oi.name}>
          <button type="button" className="tag is-link">
            {oi.name.length > 25 ? `${oi.name.substring(0, 25)}...` : oi.name}
          </button>
          <button type="button" className="tag is-delete" onClick={() => onRemoveSelected(oi)}></button>
        </div>
      ))}
    </div>
    <input {...inputProps} />
  </div>
);

const renderInputComponent = (inputProps: any) => <input {...inputProps} />;

export default function OtherIngredientSearchInput(props: Props) {
  const { onSelected } = props;
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const debouncedCallback = useDebounceCallback(async ({ value }) => {
    const response = await fetch(`/api/search/other_ingredients?q=${value}`);

    if (response.ok) {
      const result = await response.json();
      setSuggestions(result);
    } else {
      setSuggestions([]);
    }
  });
  const onSuggestionSelected = useCallback((_, { suggestion }) => onSelected(suggestion), [onSelected]);

  const inputProps: InputProps<OtherIngredient> = {
    placeholder: "Type an ingredient",
    value,
    onChange: (_, { newValue }) => {
      if (typeof newValue === "string") {
        setValue(newValue);
      } else {
        setValue((newValue as OtherIngredient).name);
      }
    },
    className: "input",
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsFetchRequested={debouncedCallback}
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={getSuggestionValue as any}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderInputComponent={
        props.isMulti ? renderMultiInputComponent(props?.selected ?? [], props.onRemoveSelected) : renderInputComponent
      }
      inputProps={inputProps}
    />
  );
}
