import { Redirect } from "@reach/router";

import * as _ from "lodash";
import React from "react";
import { useToggle } from "react-use";

import { useIngredientGroups } from "../hooks";
import { Profile as ProfileInterface } from "../interfaces";
import Layout from "../components/common/Layout";
import { useAuth0AccessTokenContext } from "../components/common/Auth0AccessToken";
import IngredientGroupSearchInput from "../components/common/IngredientGroupSearchInput";
import OtherIngredientSearchInput from "../components/common/OtherIngredientSearchInput";
import { ProductGridItem } from "../components/products";
import { useProfileContext } from "../components/remote";
import { updateProfile } from "../components/remote/Profile";

interface AuthenticatedProfileProps {
  profile: ProfileInterface;
  mutate: any;
}

function AuthenticatedProfile({ profile, mutate }: AuthenticatedProfileProps) {
  const [isPending, toggle] = useToggle(false);
  const auth0 = useAuth0AccessTokenContext();
  const igs = useIngredientGroups();

  const igLookup = _.keyBy(igs, "groupId");
  const selectedActiveIngredients = (profile.excludedActiveIngredients ?? [])
    ?.filter((i) => i)
    ?.map((i) => igLookup[i])
    ?.filter((i) => i);

  if (isPending) {
    return (
      <progress className="progress is-small is-primary" max="100" style={{ borderRadius: 0 }}>
        15%
      </progress>
    );
  }

  return (
    <Layout>
      <div className="section">
        <form className="container">
          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <label className="label">Exclude Active Ingredients</label>
                <div className="control">
                  <IngredientGroupSearchInput
                    onSelected={async (ig) => {
                      if (auth0.status === "authenticated") {
                        toggle(true);
                        try {
                          await updateProfile(
                            {
                              ...profile,
                              excludedActiveIngredients: (profile?.excludedActiveIngredients ?? []).concat([ig.id]),
                            },
                            auth0.token
                          );
                        } finally {
                          toggle(false);
                        }
                      }
                    }}
                    onRemoveSelected={async (ig) => {
                      if (auth0.status === "authenticated") {
                        toggle(true);
                        try {
                          await updateProfile(
                            {
                              ...profile,
                              excludedActiveIngredients: (profile.excludedActiveIngredients ?? [])
                                .filter((ingredient) => ingredient !== ig.groupId)
                                .filter((ig) => ig),
                            },
                            auth0.token
                          );
                        } finally {
                          toggle(false);
                        }
                      }
                    }}
                    selected={selectedActiveIngredients}
                    isMulti
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">Exclude Inactive Ingredients</label>
                <div className="control">
                  <OtherIngredientSearchInput
                    onSelected={async (oi) => {
                      if (auth0.status === "authenticated") {
                        toggle(true);
                        try {
                          await updateProfile(
                            {
                              ...profile,
                              excludedInactiveIngredients: (profile?.excludedInactiveIngredients ?? []).concat([
                                oi.name,
                              ]),
                            },
                            auth0.token
                          );
                        } finally {
                          toggle(false);
                        }
                      }
                    }}
                    onRemoveSelected={async (oi) => {
                      if (auth0.status === "authenticated") {
                        toggle(true);
                        try {
                          await updateProfile(
                            {
                              ...profile,
                              excludedInactiveIngredients: (profile?.excludedInactiveIngredients ?? [])
                                .filter((ingredient) => ingredient !== oi.name)
                                .filter((ingredient) => ingredient),
                            },
                            auth0.token
                          );
                        } finally {
                          toggle(false);
                        }
                      }
                    }}
                    selected={(profile?.excludedInactiveIngredients ?? []).map((name) => ({ name })) as any}
                    isMulti
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="section">
        <div className="container">
          <div className="columns is-multiline is-mobile">
            {(profile?.savedProducts ?? []).map((id) => (
              <div className="column is-one-third-tablet is-full-mobile" key={id}>
                <ProductGridItem id={id} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

const Profile = () => {
  const state = useProfileContext();

  if (state.status === "initial" || state.status === "pending") {
    return (
      <progress className="progress is-small is-primary" max="100" style={{ borderRadius: 0 }}>
        15%
      </progress>
    );
  }

  if (state.status !== "authenticated") {
    return <Redirect to="/" noThrow />;
  }

  return <AuthenticatedProfile profile={state.data} mutate={state.mutate} />;
};

export default Profile;
