import { useDebounceCallback } from "@react-hook/debounce";

import { navigate } from "gatsby";
import React, { useCallback, useEffect, useState } from "react";
import Autosuggest, { InputProps } from "react-autosuggest";

import { IngredientGroup } from "../../interfaces";

interface SingleProps {
  onSelected: (ig: IngredientGroup) => void;
}

interface MultiProps {
  onSelected: (ig: IngredientGroup) => void;
  onRemoveSelected: (ig: IngredientGroup) => void;
  selected: IngredientGroup[];
  isMulti: true;
}

type Props = SingleProps | MultiProps;

const getSuggestionValue = (suggestion: IngredientGroup) => suggestion;

const renderSuggestion = (suggestion: IngredientGroup) => <span>{suggestion.priming.toLowerCase()}</span>;

const renderSuggestionsContainer = ({ containerProps, children }) => {
  return (
    <div {...containerProps} className={`${containerProps.className} has-width-full is-absolute is-z-top`}>
      {children}
    </div>
  );
};

const renderMultiInputComponent = (values: string[], onRemoveSelected: (ig: IngredientGroup) => void) => (
  inputProps
) => (
  <div className="autosuggest">
    <div className="tags">
      {values.map((ig) => (
        <div className="tags has-addons" key={ig.groupId}>
          <button type="button" className="tag is-link">
            {ig.priming}
          </button>
          <button type="button" className="tag is-delete" onClick={() => onRemoveSelected(ig)}></button>
        </div>
      ))}
    </div>
    <input {...inputProps} />
  </div>
);

const renderInputComponent = (inputProps) => <input {...inputProps} />;

export default function IngredientGroupSearchInput(props: Props) {
  const { isMulti, onRemoveSelected, onSelected, selected } = props;
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const debouncedCallback = useDebounceCallback(async ({ value }) => {
    const response = await fetch(`/api/search/ingredient_groups?q=${value}`);

    if (response.ok) {
      const result = await response.json();
      setSuggestions(result);
    } else {
      setSuggestions([]);
    }
  });
  const onSuggestionSelected = useCallback((_, { suggestion }) => onSelected(suggestion), [onSelected]);

  const inputProps: InputProps<IngredientGroup> = {
    placeholder: "Type an ingredient",
    value,
    onChange: (_, { newValue }) => {
      if (typeof newValue === "string") {
        setValue(newValue);
      } else {
        setValue(newValue.priming);
      }
    },
    className: "input",
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsFetchRequested={debouncedCallback}
      onSuggestionsClearRequested={() => setSuggestions([])}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderSuggestionsContainer={renderSuggestionsContainer}
      renderInputComponent={
        isMulti ? renderMultiInputComponent(selected ?? [], onRemoveSelected) : renderInputComponent
      }
      inputProps={inputProps}
      alwaysRenderSuggestions={true}
    />
  );
}
